<template>
    <v-row>
        <v-col cols="12">
            <h1>
                {{ header }}
            </h1>
            <router-link :to="routerLink" class="secondary text-decoration-none clickable">
                <v-icon class="translate-left-25">mdi-chevron-left</v-icon>{{ linkText }}
            </router-link>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        name: "PageLinkHeader",
        props: {
            routerLink: Object,
            linkText: String,
            header: String
        }
    }
</script>