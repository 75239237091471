<template>
  <PromiseContainer :promises.sync="promises">
    <PageLinkHeader :router-link="returnRoute" :link-text="'Terug naar ' + getLinkText" :header="'Kalender van ' + getUserName"/>
    <BuildBaseCalendar :detailRoute="detailRoute" :navRoute="navRoute" />
  </PromiseContainer>
</template>

<script>
import BuildBaseCalendar from "@/views/BuildBaseCalendar";
import RouteNames from "@/router/RouteNames";
import PageLinkHeader from "@/components/shared/PageLinkHeader.vue";
import PromiseContainer from "@/components/shared/PromiseContainer.vue"

export default {
  name: "ModifyUserHours",
  components: {
    BuildBaseCalendar,
    PageLinkHeader,
    PromiseContainer
  },
  props: {
    userId: {
      type: String,
      required: true
    },
    linkText: {
      type: String,
      required: false,
    },
    returnRoute: {
      type: Object,
      required: false,
      default:() => ({name: RouteNames.USER_DETAIL}),
    },
    detailRoute: {
      type: Object,
      required: false,
    },
    navRoute: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      userName: {},
      promises: [
        this.$store.dispatch('usersModule/fetchUserName', this.userId)
        .then(data => this.userName = data)
      ]
    }
  },
  computed: {
    getUserName(){
      if (!this.userName?.firstName || !this.userName?.lastName) {
        return ''
      }
      return `${this.userName.firstName} ${this.userName.lastName}`
    },
    getLinkText(){
      if (this.linkText){
        return this.linkText
      } else {
        return this.getUserName
      }
    },
  },
}
</script>